import { Component, OnInit, ElementRef, ViewChild,OnDestroy } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import {
NgForm,
FormGroup,
FormControl,
FormGroupDirective,
FormBuilder,
Validators
} from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { ErrorStateMatcher } from "@angular/material/core";
import { LocalStorageService, SessionStorageService } from "ngx-webstorage";
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

declare var $: any;

export class MyErrorStateMatcher implements ErrorStateMatcher {
isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: "app-selectdb-cmp",
  templateUrl: "./selectDatabase.component.html"
})

export class selectDatabaseComponent implements OnInit, OnDestroy {

matcher = new MyErrorStateMatcher();
 @ViewChild("dbForm") form: NgForm;
 dbFormControl = new FormControl("", [
    Validators.required
  ]);
db: FormGroup;
databases: Array<any>;

  constructor(
  private element: ElementRef,
  private formBuilder: FormBuilder,
  private router: Router,
  private http: HttpClient,
  private spinnerService: Ng4LoadingSpinnerService,
  public sessiSt: SessionStorageService
   ) {
  }

  ngOnInit() {
    this.spinnerService.show();
    this.db = this.formBuilder.group({
            databases: ["", [Validators.required]]
        });
    const that = this;
    const dbObj: Array<{
      value: any;
    }> = [];
    this.http
      .get(
        "https://87qw2021l0.execute-api.us-east-1.amazonaws.com/prod/ds_list_databases"
      )
      .subscribe(
        result => {
          this.spinnerService.hide();
          Object.keys(result).forEach(key => {
            dbObj.push({
             value: result[key]
            });
          });
          this.databases = dbObj;
        },
        error => {
          console.log(error.message);
        }
      );

      const data = {
      accessToken: this.sessiSt.retrieve("accessToken"),
      instanceUrl: this.sessiSt.retrieve("instanceUrl")
    };
    console.log("data"+JSON.stringify(data));
     this.http
     .post(
       "https://jsbmrr9uh5.execute-api.us-east-1.amazonaws.com/prod/ds_get_namespace",
       JSON.stringify(data)
       )
     .subscribe(
     result => {
         console.log("resultnamepsace"+JSON.stringify(result));
         this.sessiSt.store("namespace", (<any>result));
         console.log("this.sessiSt.retrieve"+this.sessiSt.retrieve("namespace"));
     },
     error => {
         console.log(error.message);
     }
     )

     console.log("this.sessiSt.retrieve"+this.sessiSt.retrieve("destinationorgid"));
     const s3data = {
      orgid: this.sessiSt.retrieve("destinationorgid"),
      operation : 'Import'
    };
    this.http
      .post(
        "https://23tvjan3f7.execute-api.us-east-1.amazonaws.com/prod/ds_create_s3",
        JSON.stringify(s3data)
      )
      .subscribe(
        result => {
          console.log(JSON.stringify(result));
        },
        error => {
          console.log(error.message);
        }
      );

      const s3dataupsert = {
        orgid: this.sessiSt.retrieve("destinationorgid"),
        operation : 'Upsert'
      };
      this.http
        .post(
          "https://23tvjan3f7.execute-api.us-east-1.amazonaws.com/prod/ds_create_s3",
          JSON.stringify(s3dataupsert)
        )
        .subscribe(
          result => {
            console.log(JSON.stringify(result));
          },
          error => {
            console.log(error.message);
          }
        );
   
    // Wizard Initialization starts here
    $(".card-wizard").bootstrapWizard({
      tabClass: "nav nav-pills",
      nextSelector: ".btn-next",
      previousSelector: ".btn-previous",

      onInit: function(tab: any, navigation: any, index: any) {
        // check number of tabs and fill the entire row
        let $total = navigation.find("li").length;
        const $wizard = navigation.closest(".card-wizard");

        const $first_li = navigation.find("li:first-child a").html();
  
        $total = $wizard.find(".nav li").length;
        let $li_width = 100 / $total;

        const total_steps = $wizard.find(".nav li").length;
        let move_distance = $wizard.width() / total_steps;
        let index_temp = index;
        let vertical_level = 0;

        const mobile_device = $(document).width() < 600 && $total > 3;

        if (mobile_device) {
          move_distance = $wizard.width() / 2;
          index_temp = index % 2;
          $li_width = 50;
        }

        $wizard.find(".nav li").css("width", $li_width + "%");

        const step_width = move_distance;
        move_distance = move_distance * index_temp;

        const $current = index + 1;

        if ($current === 1 || (mobile_device === true && index % 2 === 0)) {
          move_distance -= 8;
        } else if (
          $current === total_steps ||
          (mobile_device === true && index % 2 === 1)
        ) {
          move_distance += 8;
        }

        if (mobile_device) {
          const x: any = index / 2;
          // tslint:disable-next-line:radix
          vertical_level = parseInt(x);
          vertical_level = vertical_level * 38;
        }
      }
    });
    $(".set-full-height").css("height", "auto");
    // Wizard Initialization ends here
  
    }

  ngOnDestroy() {
  }

  ondbSubmit(){

    this.spinnerService.show();
    
    this.sessiSt.store("selecteddb", this.form.value.databases);
    console.log("db"+this.form.value.databases);
    console.log("selecteddb"+this.sessiSt.retrieve("selecteddb"));

    
          const data = {
            database: this.sessiSt.retrieve("selecteddb")
          };
          const that = this;
          this.http
            .post(
              "https://87qw2021l0.execute-api.us-east-1.amazonaws.com/prod/ds_list_tables",
              JSON.stringify(data)
            )
            .subscribe(
              result => {
                console.log("dbobjList"+result);
                this.sessiSt.store("dbobjList", result);
                this.spinnerService.hide();
                this.router.navigate(["/objList"]);
              },
              error => {
                console.log(error.message);
              }
            );        
    }

}
