import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from '@angular/core';  
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { AppComponent } from './app.component';
import { AppRoutes } from "./app.routing";
import { AuthGuard } from "./service/auth-guard.service";
import { AuthService } from "./service/auth.service";
import { RoleGuard } from "./service/role-gaurd.service";
import { SnotifyModule, SnotifyService, ToastDefaults } from "ng-snotify";
import { TokenInterceptor } from "./service/token.interceptor";
import { LoginComponent } from "./pages/login/login.component";

import { SalesforceDataComponent } from "./salesforce-data/salesforce-data.component";
import { sfprofileimportComponent } from "./salesforce-data/sfprofile-import/sfprofileimport.component";
import { sfprofileexportComponent } from "./salesforce-data/sfprofile-export/sfprofileexport.component";
import { selectDatabaseComponent } from "./salesforce-data/selectDatabase/selectDatabase.component";
import { objListComponent } from "./salesforce-data/objList/objList.component";
import { objexportListComponent } from "./salesforce-data/objexportList/objexportList.component";
import { importListComponent } from "./salesforce-data/importList/importList.component";
import { exportListComponent } from "./salesforce-data/exportList/exportList.component";
import { createdatasetComponent } from "./salesforce-data/createdataset/createdataset.component";
import { datasetsuccessComponent } from "./salesforce-data/datasetsuccess/datasetsuccess.component";
import { importfromdbComponent } from "./salesforce-data/importfromdb/importfromdb.component";
import { importresultComponent } from "./salesforce-data/importresult/importresult.component";
import { retryresultComponent } from "./salesforce-data/retryresult/retryresult.component";
import { datasetoptionComponent } from "./salesforce-data/datasetoption/datasetoption.component";

import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import {
MatAutocompleteModule,
MatButtonModule,
MatButtonToggleModule,
MatCardModule,
MatCheckboxModule,
MatChipsModule,
MatDatepickerModule,
MatDialogModule,
MatExpansionModule,
MatGridListModule,
MatIconModule,
MatInputModule,
MatListModule,
MatMenuModule,
MatNativeDateModule,
MatPaginatorModule,
MatProgressBarModule,
MatProgressSpinnerModule,
MatRadioModule,
MatRippleModule,
MatSelectModule,
MatSidenavModule,
MatSliderModule,
MatSlideToggleModule,
MatSnackBarModule,
MatSortModule,
MatStepperModule,
MatTableModule,
MatTabsModule,
MatToolbarModule,
MatTooltipModule
} from "@angular/material";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {Ng2Webstorage} from "ngx-webstorage";
import { NgProgressModule } from "@ngx-progressbar/core";
import { NgProgressHttpModule } from "@ngx-progressbar/http";
import { NgProgressRouterModule } from "@ngx-progressbar/router";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
//import { SalesforceDataRoutes } from "./salesforce-data/salesforce-data.routing";
import { FieldErrorDisplayModule } from "./shared/field-error-display/field-error-display.module";

@NgModule({
  exports: [
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule
  ],
  declarations: []
})
export class MaterialModule {}

@NgModule({
  declarations: [
    AppComponent,
    SalesforceDataComponent,
    sfprofileimportComponent,
    sfprofileexportComponent,
    selectDatabaseComponent,
    objListComponent,
    objexportListComponent,
    importListComponent,
    exportListComponent,
    createdatasetComponent,
    datasetsuccessComponent,
    importfromdbComponent,
    importresultComponent,
    retryresultComponent,
    datasetoptionComponent,
    LoginComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    RouterModule.forRoot(AppRoutes),
   // RouterModule.forChild(SalesforceDataRoutes),
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    CommonModule,
    HttpClientModule,
    Ng2Webstorage,
    ReactiveFormsModule,
    NgProgressModule.forRoot(),
    NgProgressRouterModule,
    NgProgressHttpModule,
    NgxDatatableModule,
    FieldErrorDisplayModule,
    Ng4LoadingSpinnerModule.forRoot(),
    NgbModule.forRoot()
  ],
  providers: [  AuthService,
    AuthGuard,
    RoleGuard,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: "SnotifyToastConfig", useValue: ToastDefaults },
    SnotifyService],
  bootstrap: [AppComponent]
})
export class AppModule { }
