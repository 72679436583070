// import { Routes } from "@angular/router";

import { SalesforceDataComponent } from "./salesforce-data/salesforce-data.component";
import { LoginComponent } from "./pages/login/login.component";

import { selectDatabaseComponent } from "./salesforce-data/selectDatabase/selectDatabase.component";
import { objexportListComponent } from "./salesforce-data/objexportList/objexportList.component";
import { objListComponent } from "./salesforce-data/objList/objList.component";
import { importresultComponent } from "./salesforce-data/importresult/importresult.component";
import { retryresultComponent } from "./salesforce-data/retryresult/retryresult.component";
import { importListComponent } from "./salesforce-data/importList/importList.component";
import { exportListComponent } from "./salesforce-data/exportList/exportList.component";
import { createdatasetComponent } from "./salesforce-data/createdataset/createdataset.component";
import { datasetsuccessComponent } from "./salesforce-data/datasetsuccess/datasetsuccess.component";
import { datasetoptionComponent } from "./salesforce-data/datasetoption/datasetoption.component";

import { importfromdbComponent } from "./salesforce-data/importfromdb/importfromdb.component";


import { Routes } from "@angular/router";

import { AuthGuard } from "./service/auth-guard.service";

export const AppRoutes: Routes = [
  {
    path: "login",
    component: LoginComponent
  },
  {
    path: "salesforce-data",
    canActivate: [AuthGuard],
    component: SalesforceDataComponent
  },
  {
    path: "database",
    canActivate: [AuthGuard],
    component: selectDatabaseComponent
  },
  {
    path: "objList",
    canActivate: [AuthGuard],
    component: objListComponent
  },
  {
    path: "objexportList",
    canActivate: [AuthGuard],
    component: objexportListComponent
  },
  {
    path: "importList",
    canActivate: [AuthGuard],
    component: importListComponent
  },
  {
    path: "exportList",
    canActivate: [AuthGuard],
    component: exportListComponent
  },
  {
    path: "createdataset",
    canActivate: [AuthGuard],
    component: createdatasetComponent
  },
  {
    path: "datasetsuccess",
    canActivate: [AuthGuard],
    component: datasetsuccessComponent
  },
  {
    path: "importfromdb",
    canActivate: [AuthGuard],
    component: importfromdbComponent
  },
  {
    path: "importresult",
    canActivate: [AuthGuard],
    component: importresultComponent
  },
  {
    path: "retryresult",
    canActivate: [AuthGuard],
    component: retryresultComponent
  },
  {
    path: "datasetoption",
    canActivate: [AuthGuard],
    component: datasetoptionComponent
  },
  {
    path: "**",
    redirectTo: "/login"
  }
];

