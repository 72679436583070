import { Component, OnInit, ElementRef, ViewChild, OnDestroy } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { LocalStorageService, SessionStorageService } from "ngx-webstorage";
import {
  NgForm,
  FormGroup,
  FormControl,
  FormGroupDirective,
  FormBuilder,
  Validators
} from "@angular/forms";
declare var $: any;
import { Observable } from "rxjs/Observable";
import 'rxjs/Rx';
import 'rxjs/add/observable/forkJoin';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import { ErrorStateMatcher } from "@angular/material/core";
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';


export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: "app-createdataset-cmp",
  templateUrl: "./createdataset.component.html"
})

export class createdatasetComponent implements OnInit, OnDestroy {

  matcher = new MyErrorStateMatcher();
  @ViewChild("datasetForm") form: NgForm;
  datasetFormControl = new FormControl("", [
    Validators.required,
    Validators.email
  ]);
  createdataset: FormGroup;
  ErrorMessage: String;

  constructor(
    private element: ElementRef,
    private router: Router,
    private http: HttpClient,
    public sessiSt: SessionStorageService,
    private formBuilder: FormBuilder,
    private spinnerService: Ng4LoadingSpinnerService
  ) {
  }

  ngOnInit() {

    this.createdataset = this.formBuilder.group({
      datasetname: ["", [Validators.required, Validators.pattern("^[a-zA-Z][a-zA-Z0-9]*$")]]
    });

    // Wizard Initialization starts here
    $(".card-wizard").bootstrapWizard({
      tabClass: "nav nav-pills",
      nextSelector: ".btn-next",
      previousSelector: ".btn-previous",

      onInit: function (tab: any, navigation: any, index: any) {
        // check number of tabs and fill the entire row
        let $total = navigation.find("li").length;
        const $wizard = navigation.closest(".card-wizard");

        const $first_li = navigation.find("li:first-child a").html();

        $total = $wizard.find(".nav li").length;
        let $li_width = 100 / $total;

        const total_steps = $wizard.find(".nav li").length;
        let move_distance = $wizard.width() / total_steps;
        let index_temp = index;
        let vertical_level = 0;

        const mobile_device = $(document).width() < 600 && $total > 3;

        if (mobile_device) {
          move_distance = $wizard.width() / 2;
          index_temp = index % 2;
          $li_width = 50;
        }

        $wizard.find(".nav li").css("width", $li_width + "%");

        const step_width = move_distance;
        move_distance = move_distance * index_temp;

        const $current = index + 1;

        if ($current === 1 || (mobile_device === true && index % 2 === 0)) {
          move_distance -= 8;
        } else if (
          $current === total_steps ||
          (mobile_device === true && index % 2 === 1)
        ) {
          move_distance += 8;
        }

        if (mobile_device) {
          const x: any = index / 2;
          // tslint:disable-next-line:radix
          vertical_level = parseInt(x);
          vertical_level = vertical_level * 38;
        }
      }
    });
    $(".set-full-height").css("height", "auto");
    // Wizard Initialization ends here
  }

  ngOnDestroy() {
  }

  onSubmit() {

    this.spinnerService.show();

    var exportobjList = this.sessiSt.retrieve("exportobjList");
    var createtableBatch = [];
    var insertcsvBatch = [];
    var insertextidBatch = [];
    var insertrelBatch = [];
    var updaterelBatch = [];

    const data = {
      datasetname: this.form.value.datasetname
    };
    console.log("data" + JSON.stringify(data));
    const that = this;
    this.spinnerService.show();
    this.http
      .post(
        "https://up0qggu5z4.execute-api.us-east-1.amazonaws.com/prod/ds_create_db",
        JSON.stringify(data)
       )
       .subscribe(
         result => {
          console.log("resultdb" + (<any>result).database.toLowerCase());
          console.log("exportobjList" + exportobjList);
          this.sessiSt.store("dbname", (<any>result).database.toLowerCase());
          exportobjList.forEach((element) => {
            console.log("element" + element);
            const data = {
              object: element,
              s3folder: this.sessiSt.retrieve("orgid"),
              database: (<any>result).database.toLowerCase()
            };
            const tabledata = {
              object: element,
              database: (<any>result).database.toLowerCase()
            };
            createtableBatch.push(this.http.post('https://up0qggu5z4.execute-api.us-east-1.amazonaws.com/prod/ds_create_table', JSON.stringify(data)));
           // insertcsvBatch.push(this.http.post('https://up0qggu5z4.execute-api.us-east-1.amazonaws.com/prod/ds_insert_csv_to_table', JSON.stringify(data)));
            insertextidBatch.push(this.http.post('https://up0qggu5z4.execute-api.us-east-1.amazonaws.com/prod/ds_insert_extid', JSON.stringify(tabledata)));
            insertrelBatch.push(this.http.post('https://up0qggu5z4.execute-api.us-east-1.amazonaws.com/prod/ds_insert_rel_fields', JSON.stringify(tabledata)));
            updaterelBatch.push(this.http.post('https://up0qggu5z4.execute-api.us-east-1.amazonaws.com/prod/ds_update_rel_fields', JSON.stringify(tabledata)));
            
            console.log("tabledata"+JSON.stringify(tabledata));
          });
          console.log("insertextidBatch" + JSON.stringify(insertextidBatch));
          Observable.forkJoin(createtableBatch).subscribe(
            result1 => {
              console.log("resulcreatetableBatch" + JSON.stringify(result1));

              async function asyncForEach(array, callback) {
                              for (let index = 0; index < array.length; index++) {
                                await callback(array[index], index, array)
                              }
                            }
                  
                            const insertcsv = async () => {
                  
                              await asyncForEach(exportobjList, async (element) => {
                                const data = {
                                  object: element,
                                  s3folder: this.sessiSt.retrieve("orgid"),
                                  database: (<any>result).database.toLowerCase()
                                };
                            console.log("insertcsv"+JSON.stringify(data));
                       
                          
                            await this.http.post('https://up0qggu5z4.execute-api.us-east-1.amazonaws.com/prod/ds_insert_csv_to_table', 
                            JSON.stringify(data)).toPromise().
                            then(async response => 
                             {
                               console.log("response"+response);
                              })
                              .catch( error => { console.error("errorrrrrr" + error.name);
                              console.log("insertcsv in error "+JSON.stringify(data));
                               })   
                            })
                          }
                          insertcsv().then ( x => {
                            console.log("Finished:"+JSON.stringify(x));

                            Observable.forkJoin(insertextidBatch).subscribe(
                                    result3 => {
                                      console.log("insertextidBatch" + JSON.stringify(result3));
                                      Observable.forkJoin(insertrelBatch).subscribe(
                                        result4 => {
                                          console.log("insertrelBatch" + JSON.stringify(result4));
                
                                          async function asyncForEach(array, callback) {
                                            for (let index = 0; index < array.length; index++) {
                                              await callback(array[index], index, array)
                                            }
                                          }
                                
                                          const start = async () => {
                                
                                            await asyncForEach(exportobjList, async (element) => {
                                              var updaterel = {
                                                object: element,
                                                database: (<any>result).database.toLowerCase()
                                              };
                                          console.log("updaterel"+JSON.stringify(updaterel));
                                     
                                        
                                          await this.http.post('https://up0qggu5z4.execute-api.us-east-1.amazonaws.com/prod/ds_update_rel_fields', 
                                          JSON.stringify(updaterel)).toPromise().
                                          then(async response => 
                                           {
                                             console.log("response"+response);
                                            })
                                            .catch( error => { console.error("errorrrrrr" + error.name);
                                            console.log("updaterel in error "+JSON.stringify(updaterel));
                                            if (error.name == 'HttpErrorResponse')
                                            {
                                              this.http
                                              .post(
                                                "https://up0qggu5z4.execute-api.us-east-1.amazonaws.com/prod/ds_update_rel_fields",
                                                JSON.stringify(updaterel)
                                               )
                                               .subscribe(
                                                 resultupdt => {
                                                  console.log("resultupdt" + JSON.stringify(resultupdt));
                                                },
                                                 error => {
                                                  console.log("errorresultupdt" + JSON.stringify(error));
                                                }
                                              )
                                            }
                                             })   
                                          })
                                        }
                                        start().then ( x => {
                                          console.log("Finished:"+JSON.stringify(x));
                                          this.spinnerService.hide();
                                          this.router.navigate(["/datasetsuccess"]);
                                      }
                                      );
                
                                          // Observable.forkJoin(updaterelBatch).subscribe(
                                          //   result5 => {
                                          //     console.log("updaterelBatch" + JSON.stringify(result5));
                                          //     this.spinnerService.hide();
                                          //     this.router.navigate(["/datasetsuccess"]);
                                          //   },
                                          //   error => {
                                          //     console.log("error.message.....1"+ JSON.stringify(error));
                                          //     console.log("error.message11111" + error);
                                          //   }
                                          // )
                                        },
                                        error => {
                                          console.log("error.message1" + JSON.stringify(error));
                                        }
                                      )
                                    },
                                    error => {
                                      console.log("error.message1" + JSON.stringify(error));
                                    }
                                  )
                        }
                        );

              // Observable.forkJoin(insertcsvBatch).subscribe(
              //   result2 => {
              //     console.log("resulinsertcsvBatch" + JSON.stringify(result2));
              //     Observable.forkJoin(insertextidBatch).subscribe(
              //       result3 => {
              //         console.log("insertextidBatch" + JSON.stringify(result3));
              //         Observable.forkJoin(insertrelBatch).subscribe(
              //           result4 => {
              //             console.log("insertrelBatch" + JSON.stringify(result4));

              //             async function asyncForEach(array, callback) {
              //               for (let index = 0; index < array.length; index++) {
              //                 await callback(array[index], index, array)
              //               }
              //             }
                
              //             const start = async () => {
                
              //               await asyncForEach(exportobjList, async (element) => {
              //                 var updaterel = {
              //                   object: element,
              //                   database: (<any>result).database.toLowerCase()
              //                 };
              //             console.log("updaterel"+JSON.stringify(updaterel));
                     
                        
              //             await this.http.post('https://up0qggu5z4.execute-api.us-east-1.amazonaws.com/prod/ds_update_rel_fields', 
              //             JSON.stringify(updaterel)).toPromise().
              //             then(async response => 
              //              {
              //                console.log("response"+response);
              //               })
              //               .catch( error => { console.error("errorrrrrr" + error.name);
              //               console.log("updaterel in error "+JSON.stringify(updaterel));
              //               if (error.name == 'HttpErrorResponse')
              //               {
              //                 this.http
              //                 .post(
              //                   "https://up0qggu5z4.execute-api.us-east-1.amazonaws.com/prod/ds_update_rel_fields",
              //                   JSON.stringify(updaterel)
              //                  )
              //                  .subscribe(
              //                    resultupdt => {
              //                     console.log("resultupdt" + JSON.stringify(resultupdt));
              //                   },
              //                    error => {
              //                     console.log("errorresultupdt" + JSON.stringify(error));
              //                   }
              //                 )
              //               }
              //                })   
              //             })
              //           }
              //           start().then ( x => {
              //             console.log("Finished:"+JSON.stringify(x));
              //             this.spinnerService.hide();
              //             this.router.navigate(["/datasetsuccess"]);
              //         }
              //         );

              //             // Observable.forkJoin(updaterelBatch).subscribe(
              //             //   result5 => {
              //             //     console.log("updaterelBatch" + JSON.stringify(result5));
              //             //     this.spinnerService.hide();
              //             //     this.router.navigate(["/datasetsuccess"]);
              //             //   },
              //             //   error => {
              //             //     console.log("error.message.....1"+ JSON.stringify(error));
              //             //     console.log("error.message11111" + error);
              //             //   }
              //             // )
              //           },
              //           error => {
              //             console.log("error.message1" + JSON.stringify(error));
              //           }
              //         )
              //       },
              //       error => {
              //         console.log("error.message1" + JSON.stringify(error));
              //       }
              //     )
              //   },
              //   error => {
              //     console.log("error.message1" + JSON.stringify(error));
              //   }
              // )
            },
            error => {
              console.log("error.message2" + JSON.stringify(error));
            }
          )
          },
        error => {
          console.log("error.error.errorMessage" + error.error.errorMessage);
          return this.ErrorMessage = error.error.errorMessage;
        }
       )
  }

}
