import { Component, OnInit, ElementRef, ViewChild, OnDestroy } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { LocalStorageService, SessionStorageService } from "ngx-webstorage";
import {
  NgForm,
  FormGroup,
  FormControl,
  FormGroupDirective,
  FormBuilder,
  Validators
} from "@angular/forms";
declare var $: any;
import { Observable } from "rxjs/Observable";
import 'rxjs/add/observable/forkJoin';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';


@Component({
  selector: "app-exportList-cmp",
  templateUrl: "./exportList.component.html"
})

export class exportListComponent implements OnInit, OnDestroy {

  constructor(
    private element: ElementRef,
    private router: Router,
    private http: HttpClient,
    public sessiSt: SessionStorageService,
    private spinnerService: Ng4LoadingSpinnerService
  ) {
  }

  matchedArr: Array<any>;
  ngOnInit() {
    this.spinnerService.show();
    this.matchedArr = this.sessiSt.retrieve("getuniquematch");
    this.spinnerService.hide();
      // Wizard Initialization starts here
      $(".card-wizard").bootstrapWizard({
        tabClass: "nav nav-pills",
        nextSelector: ".btn-next",
        previousSelector: ".btn-previous",
  
        onInit: function(tab: any, navigation: any, index: any) {
          // check number of tabs and fill the entire row
          let $total = navigation.find("li").length;
          const $wizard = navigation.closest(".card-wizard");
  
          const $first_li = navigation.find("li:first-child a").html();
    
          $total = $wizard.find(".nav li").length;
          let $li_width = 100 / $total;
  
          const total_steps = $wizard.find(".nav li").length;
          let move_distance = $wizard.width() / total_steps;
          let index_temp = index;
          let vertical_level = 0;
  
          const mobile_device = $(document).width() < 600 && $total > 3;
  
          if (mobile_device) {
            move_distance = $wizard.width() / 2;
            index_temp = index % 2;
            $li_width = 50;
          }
  
          $wizard.find(".nav li").css("width", $li_width + "%");
  
          const step_width = move_distance;
          move_distance = move_distance * index_temp;
  
          const $current = index + 1;
  
          if ($current === 1 || (mobile_device === true && index % 2 === 0)) {
            move_distance -= 8;
          } else if (
            $current === total_steps ||
            (mobile_device === true && index % 2 === 1)
          ) {
            move_distance += 8;
          }
  
          if (mobile_device) {
            const x: any = index / 2;
            // tslint:disable-next-line:radix
            vertical_level = parseInt(x);
            vertical_level = vertical_level * 38;
          }
        }
      });
      $(".set-full-height").css("height", "auto");
      // Wizard Initialization ends here
  }

  ngOnDestroy() {
  }

  onSubmit() {

    this.spinnerService.show();

    var accessToken = this.sessiSt.retrieve("accessToken");
    var instanceUrl = this.sessiSt.retrieve("instanceUrl");
    var s3folder = this.sessiSt.retrieve("orgid");
    console.log("accessToken" + accessToken);

    this.sessiSt.store("exportobjList", this.matchedArr);

    let observableBatch = [];
    const that = this;

    this.matchedArr.forEach((element) => {
      console.log("element" + element);
      const data = {
        accessToken: accessToken,
        instanceUrl: instanceUrl,
        object: element,
        s3folder: s3folder
      };
      observableBatch.push(this.http.post('https://3nybj80hha.execute-api.us-east-1.amazonaws.com/prod/ds_create_exportcsv', JSON.stringify(data)));
    });

    console.log("observableBatch" + observableBatch);
    Observable.forkJoin(observableBatch).subscribe(
      result => {
        this.spinnerService.hide();
        this.router.navigate(["/createdataset"]);
       // this.router.navigate(["/datasetsuccess"]);
        console.log("result12343" + result);
      },
      error => {
        console.log(error.message);
      }
    )
  }
}
