import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, ViewEncapsulation } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import {
  NgForm,
  FormGroup,
  FormControl,
  FormGroupDirective,
  FormBuilder,
  Validators
} from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { ErrorStateMatcher } from "@angular/material/core";
import { LocalStorageService, SessionStorageService } from "ngx-webstorage";
import { Observable } from "rxjs/Observable";
import 'rxjs/add/observable/forkJoin';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Angular5Csv } from 'angular5-csv/Angular5-csv';

declare interface DataTable {
  headerRow: string[];
  footerRow: string[];
  dataRows: { objectname: string , status: string, recordsprocessed: number,recordserror : number,error : string }[];
}

declare var $: any;

@Component({
  selector: "app-datasetoption-cmp",
  templateUrl: "./datasetoption.component.html"
})

export class datasetoptionComponent implements OnInit, AfterViewInit {

  datasetName : String;
  datasetDeleted : String;
  showMyMessage : Boolean;
  constructor(
    private element: ElementRef,
    private formBuilder: FormBuilder,
    private router: Router,
    private http: HttpClient,
    private spinnerService: Ng4LoadingSpinnerService,
    public sessiSt: SessionStorageService
  ) 
  {
    //this.importResultRows = [];
  }


  ngOnInit() {

   this.datasetName =  this.sessiSt.retrieve("selecteddb");
   //this.datasetName =  "krutitesting";
 
// Wizard Initialization starts here
$(".card-wizard").bootstrapWizard({
  tabClass: "nav nav-pills",
  nextSelector: ".btn-next",
  previousSelector: ".btn-previous",

  onInit: function(tab: any, navigation: any, index: any) {
    // check number of tabs and fill the entire row
    let $total = navigation.find("li").length;
    const $wizard = navigation.closest(".card-wizard");

    const $first_li = navigation.find("li:first-child a").html();

    $total = $wizard.find(".nav li").length;
    let $li_width = 100 / $total;

    const total_steps = $wizard.find(".nav li").length;
    let move_distance = $wizard.width() / total_steps;
    let index_temp = index;
    let vertical_level = 0;

    const mobile_device = $(document).width() < 600 && $total > 3;

    if (mobile_device) {
      move_distance = $wizard.width() / 2;
      index_temp = index % 2;
      $li_width = 50;
    }

    $wizard.find(".nav li").css("width", $li_width + "%");

    const step_width = move_distance;
    move_distance = move_distance * index_temp;

    const $current = index + 1;

    if ($current === 1 || (mobile_device === true && index % 2 === 0)) {
      move_distance -= 8;
    } else if (
      $current === total_steps ||
      (mobile_device === true && index % 2 === 1)
    ) {
      move_distance += 8;
    }

    if (mobile_device) {
      const x: any = index / 2;
      // tslint:disable-next-line:radix
      vertical_level = parseInt(x);
      vertical_level = vertical_level * 38;
    }
  }
});
$(".set-full-height").css("height", "auto");
// Wizard Initialization ends here

  }


  ngOnDestroy() {
  }

  ngAfterViewInit() {

  }

  onDelete(){
    this.spinnerService.show();
     const data = {
     datasetname: this.sessiSt.retrieve("selecteddb")
     };
     const that = this;
     this.http
       .post(
         "https://23tvjan3f7.execute-api.us-east-1.amazonaws.com/prod/ds_drop_db",
         JSON.stringify(data)
       )
       .subscribe(
         result => {
           this.showMyMessage = true
           this.spinnerService.hide();
           this.datasetDeleted = this.sessiSt.retrieve("selecteddb")+"deleted Successfully"
           setTimeout(() => {
           this.router.navigate(["/salesforce-data"]);
          }, 2000)
         },
         error => {
           console.log(error.message);
         }
       ); 
         
  }

  onKeep(){

    this.spinnerService.show();
    const data = {
      database: this.sessiSt.retrieve("selecteddb")
      };
      const that = this;
      this.http
        .post(
          "https://23tvjan3f7.execute-api.us-east-1.amazonaws.com/prod/ds_remove_isinserted_flg",
          JSON.stringify(data)
        )
        .subscribe(
          result => {
            this.spinnerService.hide();
            this.router.navigate(["/salesforce-data"]);
          },
          error => {
            console.log(error.message);
          }
        ); 

  }


}
