import {
  Component,
  OnInit,
  OnDestroy,
  ElementRef,
  ViewChild
} from "@angular/core";
import {
  FormControl,
  FormGroupDirective,
  NgForm,
  Validators,
  FormGroup
} from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { FormBuilder, AbstractControl } from "@angular/forms";
import { PasswordValidation } from "./password-validator.component";
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import swal from "sweetalert2";
import { AuthService } from "../../service/auth.service";
import { Ng2Webstorage, LocalStorageService } from "ngx-webstorage";
/*import { AuthService } from "angularx-social-login";
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  LinkedInLoginProvider,
  SocialUser
} from "angularx-social-login";*/

declare var $: any;

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: "app-login-cmp",
  templateUrl: "./login.component.html"
  // styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit, OnDestroy {
  // private user: SocialUser;
  // private loggedIn: boolean;
  @ViewChild("usrForm") form: NgForm;
  didFail = false;
  isLoading = false;
  emailFormControl = new FormControl("", [
    Validators.required,
    Validators.email
  ]);
  matcher = new MyErrorStateMatcher();
  login: FormGroup;
  type: FormGroup;
  email: string;
  password: string;
  errorMessage: any = null;
  test: Date = new Date();
  private toggleButton: any;
  private sidebarVisible: boolean;
  private nativeElement: Node;
  constructor(
    private element: ElementRef,
    public router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private localSt: LocalStorageService
  ) {
    this.sidebarVisible = false;
  }
  /*signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  signInWithLinkedIn(): void {
    this.authService.signIn(LinkedInLoginProvider.PROVIDER_ID);
  }

  signOut(): void {
    this.authService.signOut();
  }*/
  ngOnInit() {
    this.localSt.clear("orgCreateStatus");
    /*this.authService.authState.subscribe(user => {
      console.log(user);
      this.user = user;
      this.loggedIn = user != null;
    });*/
    /** Toggle Button for Small Screen */
    const navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName("navbar-toggler")[0];
    const body = document.getElementsByTagName("body")[0];
    body.classList.add("login-page");
    body.classList.add("off-canvas-sidebar");
    this.sidebarVisible = false;
    /** Intialize the login form validations */
    this.login = this.formBuilder.group({
    //  username: ["", [Validators.required, Validators.pattern("^[a-zA-Z]*$")]],
      username: ["", Validators.required],
      password: ["", Validators.required]
    });
    /** Initializing the auth services */
    this.authService.authIsLoading.subscribe(
      (isLoading: boolean) => (this.isLoading = isLoading)
    );
    this.authService.authDidFail.subscribe(
      (didFail: boolean) => (this.didFail = didFail)
    );
  }

  onSubmit() {
    const usrName = this.form.value.username;
    const password = this.form.value.password;
    this.authService.signIn(usrName, password);
  }

  onForgotPassword() {
    this.router.navigate(["/pages/forgotPassword"]);
  }

  onAccountActivate() {
    this.router.navigate(["/pages/resend"]);
  }

  ngOnDestroy() {
    const body = document.getElementsByTagName("body")[0];
    body.classList.remove("login-page");
    body.classList.remove("off-canvas-sidebar");
  }
  /** Toggle Button for Small Screen */
  sidebarClose() {
    const body = document.getElementsByTagName("body")[0];
    this.toggleButton.classList.remove("toggled");
    this.sidebarVisible = false;
    body.classList.remove("nav-open");
  }
  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const body = document.getElementsByTagName("body")[0];
    setTimeout(function() {
      toggleButton.classList.add("toggled");
    }, 300);
    body.classList.add("nav-open");
    this.sidebarVisible = true;
  }
  sidebarToggle() {
    // console.log("toggle clicked");
    const toggleButton = this.toggleButton;
    const body = document.getElementsByTagName("body")[0];
    if (this.sidebarVisible === false) {
      // console.log("opening");
      this.sidebarOpen();
    } else {
      // console.log("closing");
      this.sidebarClose();
    }
  }

  /** Login form validation */
  isFieldValid(form: FormGroup, field: string) {
    return !form.get(field).valid && form.get(field).touched;
  }
  displayFieldCss(form: FormGroup, field: string) {
    return {
      "has-error": this.isFieldValid(form, field),
      "has-feedback": this.isFieldValid(form, field)
    };
  }
  onType() {
    // console.log(this.type);
    if (this.type.valid) {
      // console.log("form submitted");
    } else {
      this.validateAllFormFields(this.type);
    }
  }
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      // console.log(field);
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}
