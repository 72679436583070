// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  region: "us-east-1",
  identityPoolId: "us-east-1:75466aec-8274-46ef-a53c-4d5bdc2e8e4d",
  userPoolId: "us-east-1_W4lVFBAnd",
  clientId: "5qms26180u35du9ajk290dd8d4",
  bucketRegion: "us-east-1"
};
