import { Component, OnInit, ElementRef, ViewChild, OnDestroy } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { LocalStorageService, SessionStorageService } from "ngx-webstorage";
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Observable } from "rxjs/Observable";
import 'rxjs/add/observable/forkJoin';
import {
  NgForm,
  FormGroup,
  FormControl,
  FormGroupDirective,
  FormBuilder,
  Validators
} from "@angular/forms";
import { ArrayObservable } from "rxjs/observable/ArrayObservable";
import { bindCallback } from "rxjs/observable/bindCallback";
import { async } from "@angular/core/testing";

declare interface DataTable {
  headerRow: string[];
  footerRow: string[];
  dataRows: { objectname: string, status: string, recordsprocessed: number, recordserror: number, error: string, runid: string }[];
}

declare var $: any;

@Component({
  selector: "app-importList-cmp",
  templateUrl: "./importList.component.html"
})

export class importListComponent implements OnInit, OnDestroy {

  constructor(
    private element: ElementRef,
    private router: Router,
    private http: HttpClient,
    private spinnerService: Ng4LoadingSpinnerService,
    public sessiSt: SessionStorageService
  ) {
    this.importResultRows = [];
  }

  @ViewChild("myTable") table: any;
  public dataTable: DataTable;

  importResultRows = [
    {
      objectname: "",
      status: "",
      recordsprocessed: "",
      recordserror: "",
      error: "",
      runid: ""
    }
  ];

  matchedArr: Array<any>;
  show: boolean;
  showlist: boolean;
  showerror: boolean;
  ngOnInit() {
    this.matchedArr = this.sessiSt.retrieve("getmatch");
    this.showlist = true;
    this.showerror = false;
    console.log("this.matchedArr" + this.matchedArr);

    // Wizard Initialization starts here
    $(".card-wizard").bootstrapWizard({
      tabClass: "nav nav-pills",
      nextSelector: ".btn-next",
      previousSelector: ".btn-previous",

      onInit: function (tab: any, navigation: any, index: any) {
        // check number of tabs and fill the entire row
        let $total = navigation.find("li").length;
        const $wizard = navigation.closest(".card-wizard");

        const $first_li = navigation.find("li:first-child a").html();

        $total = $wizard.find(".nav li").length;
        let $li_width = 100 / $total;

        const total_steps = $wizard.find(".nav li").length;
        let move_distance = $wizard.width() / total_steps;
        let index_temp = index;
        let vertical_level = 0;

        const mobile_device = $(document).width() < 600 && $total > 3;

        if (mobile_device) {
          move_distance = $wizard.width() / 2;
          index_temp = index % 2;
          $li_width = 50;
        }

        $wizard.find(".nav li").css("width", $li_width + "%");

        const step_width = move_distance;
        move_distance = move_distance * index_temp;

        const $current = index + 1;

        if ($current === 1 || (mobile_device === true && index % 2 === 0)) {
          move_distance -= 8;
        } else if (
          $current === total_steps ||
          (mobile_device === true && index % 2 === 1)
        ) {
          move_distance += 8;
        }

        if (mobile_device) {
          const x: any = index / 2;
          // tslint:disable-next-line:radix
          vertical_level = parseInt(x);
          vertical_level = vertical_level * 38;
        }
      }
    });
    $(".set-full-height").css("height", "auto");
    // Wizard Initialization ends here
  }

  onComplete(insertresult) {
    console.log("Oncompleteinsertresult" + JSON.stringify(insertresult));
    this.showlist = false;
    this.show = true;
    this.importResultRows = insertresult;
    this.importResultRows = this.importResultRows.slice();
  }

  ngOnDestroy() {
  }

  onSubmit() {

    this.showerror = false;
    this.spinnerService.show();


    let insertresult = [];
    let upsertresult = [];
    let erroredlist = [];

    var accessToken = this.sessiSt.retrieve("accessToken");
    var instanceUrl = this.sessiSt.retrieve("instanceUrl");
    console.log("this.matchedArr" + this.matchedArr);
    console.log("JSON.stringify" + JSON.stringify(this.matchedArr).replace(/\[/g, '').replace(/\]/g, '').replace(/"/g, ''));
    const data = {
      accessToken: accessToken,
      instanceUrl: instanceUrl,
      object: JSON.stringify(this.matchedArr).replace(/\[/g, '').replace(/\]/g, '').replace(/"/g, ''),
      namespace: this.sessiSt.retrieve("namespace")
    };

    const that = this;

    const triggerdata = {
      accessToken: accessToken,
      instanceUrl: instanceUrl,
      updatetrigger: false
    };

    this.http
      .post(
        "https://jsbmrr9uh5.execute-api.us-east-1.amazonaws.com/prod/ds_update_sf_triggers",
        JSON.stringify(triggerdata)
      )
      .subscribe(
        result => {
          console.log("triggerresult" + JSON.stringify(result));
        }, error => {
          this.showerror = true;
          this.spinnerService.hide();
          console.log(error.message);
        }
      )

    var runid = Math.random().toString(36).substring(7);
    this.sessiSt.store("runid", runid);

    this.sessiSt.store("sfextidlist", JSON.stringify(this.matchedArr).replace(/\[/g, '').replace(/\]/g, '').replace(/"/g, ''));

    console.log("JSON.stringify(data)deploy" + JSON.stringify(data));
    this.http
      .post(
        "https://jsbmrr9uh5.execute-api.us-east-1.amazonaws.com/prod/ds_deploy_sf_extid",
        JSON.stringify(data)
      )
      .subscribe(
        result => {
          console.log("deployresult" + JSON.stringify(result));
          this.sessiSt.store("resultid", result);

          const deploydata = {
            accessToken: accessToken,
            instanceUrl: instanceUrl,
            deployId: result
          };

          const deploystatus = async () => {
            await this.http
              .post(
                "https://23tvjan3f7.execute-api.us-east-1.amazonaws.com/prod/ds_check_deploystatus",
                JSON.stringify(deploydata)).toPromise()
              .then(
                result => {
                  console.log("deployresultstatus" + JSON.stringify(result));
                  if (result == 'Succeeded') {
                    console.log("Result........" + result);
                    var accessToken = this.sessiSt.retrieve("accessToken");
                    var instanceUrl = this.sessiSt.retrieve("instanceUrl");
                    console.log("this.matchedArr" + this.matchedArr);
                    console.log("JSON.stringify" + JSON.stringify(this.matchedArr).replace(/\[/g, "").replace(/\]/g, "").replace(/"/g, "'"));
                    const rankdata = {
                      accessToken: accessToken,
                      instanceUrl: instanceUrl,
                      object: JSON.stringify(this.matchedArr).replace(/\[/g, "").replace(/\]/g, "").replace(/"/g, "'"),
                      database: this.sessiSt.retrieve("selecteddb")
                    };

                    var insertionlist = [];
                    console.log("JSON.stringify(data)" + JSON.stringify(rankdata));
                    this.http
                      .post(
                        "https://87qw2021l0.execute-api.us-east-1.amazonaws.com/prod/ds_get_insertion_order",
                        JSON.stringify(rankdata)
                      )
                      .subscribe(
                        result => {
                          console.log("Result" + JSON.parse(JSON.stringify(result)));
                          var sorted = JSON.parse(JSON.stringify(result)).sort(function (a, b) {
                            return a.rank - b.rank;
                          });

                          for (var i = 0; i < sorted.length; i++) {
                            insertionlist.push(sorted[i].object)
                          }
                          console.log("insertionlist" + insertionlist);

                          async function asyncForEach(array, callback) {
                            for (let index = 0; index < array.length; index++) {
                              await callback(array[index], index, array)
                            }
                          }

                          const start = async () => {

                            await asyncForEach(insertionlist, async (element) => {
                              var insertcsvdata = {
                                object: element,
                                s3folder: this.sessiSt.retrieve("destinationorgid"),
                                namespace: this.sessiSt.retrieve("namespace"),
                                database: this.sessiSt.retrieve("selecteddb"),
                                runid: runid
                              };
                              console.log("insertcsvdata" + JSON.stringify(insertcsvdata));
                              var objinsert = {
                                object: element,
                                s3folder: this.sessiSt.retrieve("destinationorgid"),
                                accessToken: accessToken,
                                instanceUrl: instanceUrl,
                                namespace: this.sessiSt.retrieve("namespace")
                              };
                              console.log("objinsert......" + JSON.stringify(objinsert));
                              var updatetablesuccess = {
                                object: element,
                                database: this.sessiSt.retrieve("selecteddb"),
                                runid: runid,
                                isinserted: 'true',
                                operation: 'insert'
                              };

                              var updatetableerror = {
                                object: element,
                                database: this.sessiSt.retrieve("selecteddb"),
                                runid: runid,
                                isinserted: 'false',
                                operation: 'insert'
                              };


                              await this.http.post('https://87qw2021l0.execute-api.us-east-1.amazonaws.com/prod/ds_insertcsv_db_to_s3',
                                JSON.stringify(insertcsvdata)).toPromise().
                                then(async response => {
                                  console.log("response" + response);
                                  if (response == 'uploaded') {
                                    upsertionlist.push(element);
                                    await this.http.post('https://jsbmrr9uh5.execute-api.us-east-1.amazonaws.com/prod/ds_sf_object_insert',
                                      JSON.stringify(objinsert)).toPromise().
                                      then(async res => {
                                        if ((<any>res).status == 'success') {

                                          console.log("Completed" + element)
                                          if ((<any>res).recordsfailed > 0) {

                                            await this.http.post('https://87qw2021l0.execute-api.us-east-1.amazonaws.com/prod/ds_update_inserted_flag',
                                              JSON.stringify(updatetableerror)).toPromise().
                                              then(result => {
                                                insertresult.push({ runid: runid, objectname: element, status: "error", recordsprocessed: (<any>res).recordsprocessed, recordserror: (<any>res).recordsfailed, error: (<any>res).error });
                                                erroredlist.push(element);
                                                console.log("erroredlisterror" + erroredlist);
                                                this.onComplete(insertresult);
                                              })
                                              .catch(error => {
                                                console.error("errorrupdateinsertedflg" + error.name)
                                                erroredlist.push(element);
                                                this.showerror = true;
                                                this.spinnerService.hide();
                                              })
                                          } else {
                                            insertresult.push({ runid: runid, objectname: element, status: "success", recordsprocessed: (<any>res).recordsprocessed, recordserror: (<any>res).recordsfailed, error: (<any>res).error });
                                            this.onComplete(insertresult);
                                            await this.http.post('https://87qw2021l0.execute-api.us-east-1.amazonaws.com/prod/ds_update_inserted_flag',
                                              JSON.stringify(updatetablesuccess)).toPromise().
                                              then(result => {
                                                console.log("result" + result);

                                              })
                                              .catch(error => {
                                                console.error("errorrupdateinsertedflg" + error.name)
                                                this.showerror = true;
                                                this.spinnerService.hide();
                                              })
                                          }
                                        }
                                        if ((<any>res).status == 'error') {
                                          var batchdata = {
                                            accessToken: accessToken,
                                            instanceUrl: instanceUrl,
                                            jobid : (<any>res).errorbatch.jobId,
                                            batchid : (<any>res).errorbatch.batchId
                                          };
            
                                          const batchstatus = async () => {
                                            await this.http
                                              .post(
                                                "https://jsbmrr9uh5.execute-api.us-east-1.amazonaws.com/prod/ds_check_batchstatus",
                                                JSON.stringify(batchdata)).toPromise()
                                              .then(
                                                batchresult => {
                                                  if ((<any>batchresult).state == 'Completed') {
                                                    if ((<any>batchresult).recordsfailed > 0){
                                                  insertresult.push({ runid: runid, objectname: element, status: "error", recordsprocessed: (<any>batchresult).recordsprocessed, recordserror: (<any>batchresult).recordsfailed, error: '' });
                                                  this.onComplete(insertresult);
                                                  erroredlist.push(element);
                                                  }
                                                  else{
                                                  insertresult.push({ runid: runid, objectname: element, status: "success", recordsprocessed: (<any>batchresult).recordsprocessed, recordserror: (<any>batchresult).recordsfailed, error: '' });
                                                  this.onComplete(insertresult);
                                                  }
                                                }
                                                  else{
                                                    setTimeout(
                                                      function () {
                                                        batchstatus();
                                                      }, 30000);
                                                  }
                                                },
                                                error => {
                                                  this.showerror = true;
                                                  this.spinnerService.hide();
                                                  console.log(error.message);
                                                }
                                              )
                                              }
                                              batchstatus();
                                        }

                                      })
                                      .catch(error => {
                                        console.error("errorrsfobjectinsert" + error.name)
                                        erroredlist.push(element);
                                        this.showerror = true;
                                        this.spinnerService.hide();
                                      }
                                      )
                                  }
                                  else if (response == 'uploadedwithoutrecords') {
                                    insertresult.push({ runid: runid, objectname: element, status: "success", recordsprocessed: 0, recordserror: 0, error: '' });
                                    this.onComplete(insertresult);
                                  }
                                  else {
                                    console.log("not uploaded");
                                  }
                                })
                                .catch(error => {
                                  console.error("errorr---insertcsvdbtos3" + error.name)
                                  erroredlist.push(element);
                                }
                                )
                            })
                          }
                          start().then(x => {
                            console.log("Finished:" + JSON.stringify(x));
                            console.log("insertresult" + JSON.stringify(insertresult));
                            console.log("upsertionlist" + upsertionlist);

                            console.log("erroredList" + erroredlist);
                            this.sessiSt.store("errorlist", erroredlist);

                            console.log("errorlistttt....." + this.sessiSt.retrieve("errorlist"))
                            this.sessiSt.store("insertresult", insertresult);

                            const upsertstart = async () => {

                              await asyncForEach(upsertionlist, async (upsertelement) => {

                                const upsertdata = {
                                  object: upsertelement,
                                  s3folder: this.sessiSt.retrieve("destinationorgid"),
                                  accessToken: accessToken,
                                  instanceUrl: instanceUrl,
                                  namespace: this.sessiSt.retrieve("namespace")
                                };


                                await this.http.post('https://jsbmrr9uh5.execute-api.us-east-1.amazonaws.com/prod/ds_sf_object_upsert',
                                  JSON.stringify(upsertdata)).toPromise().
                                  then(async res1 => {
                                    if ((<any>res1).status == 'success') {
                                      upsertresult.push({ runid: runid, objectname: upsertelement, status: "success", recordsprocessed: (<any>res1).recordsprocessed, recordserror: (<any>res1).recordsfailed, error: (<any>res1).error });
                                      console.log("Upsert Completed" + upsertelement)
                                    }
                                    if ((<any>res1).status == 'error') {
                                      console.log("Upsert error" + JSON.stringify(res1) + upsertelement)
                                    }

                                  })
                                  .catch(error => {
                                    console.error("errorrsfobjectupsert" + error.name)
                                    this.showerror = true;
                                    this.spinnerService.hide();
                                  }
                                  )
                              })
                            }
                            upsertstart().then(y => {
                              console.log("Finished....y:" + JSON.stringify(y));
                              console.log("upsertresult" + JSON.stringify(upsertresult));
                              const enabletriggerdata = {
                                accessToken: accessToken,
                                instanceUrl: instanceUrl,
                                updatetrigger: true
                              };
                              this.http
                                .post(
                                  "https://jsbmrr9uh5.execute-api.us-east-1.amazonaws.com/prod/ds_update_sf_triggers",
                                  JSON.stringify(enabletriggerdata)
                                )
                                .subscribe(
                                  result => {
                                    console.log("triggerresult" + JSON.stringify(result));
                                    this.spinnerService.hide();
                                    this.router.navigate(["/importresult"]);
                                  }, error => {
                                    console.log(error.message);
                                    this.showerror = true;
                                    this.spinnerService.hide();
                                    this.router.navigate(["/importresult"]);
                                  }
                                )
                            },
                              error => {
                                console.log("upsertion" + error.message);
                                const enabletriggerdata = {
                                  accessToken: accessToken,
                                  instanceUrl: instanceUrl,
                                  updatetrigger: true
                                };
                                this.http
                                  .post(
                                    "https://jsbmrr9uh5.execute-api.us-east-1.amazonaws.com/prod/ds_update_sf_triggers",
                                    JSON.stringify(enabletriggerdata)
                                  )
                                  .subscribe(
                                    result => {
                                      console.log("triggerresult" + JSON.stringify(result));
                                      this.showerror = true;
                                      this.spinnerService.hide();
                                    }, error => {
                                      console.log(error.message);
                                      this.showerror = true;
                                      this.spinnerService.hide();
                                    }
                                  )
                              }
                            )
                          }
                          );
                        },
                        error => {
                          this.showerror = true;
                          this.spinnerService.hide();
                          console.log(error.message);
                        }
                      )
                  }
                  else {
                    console.log("resulttttt" + result);
                    if (result == 'InProgress' || result == 'Pending') {
                      setTimeout(
                        function () {
                          deploystatus();
                        }, 30000);
                    }
                    else {
                      console.log("Deploy error")
                      this.showerror = true;
                      this.spinnerService.hide();
                    }
                  }
                },
                error => {
                  this.showerror = true;
                  this.spinnerService.hide();
                  console.log(error.message);
                }
              )
          }
          deploystatus();
        },
        error => {
          this.showerror = true;
          this.spinnerService.hide();
          console.log(error.message);
        }
      )


    let upsertcsvBatch = [];
    var upsertionlist = [];

    this.matchedArr.forEach((element) => {
      console.log("element" + element);
      const data = {
        object: element,
        s3folder: this.sessiSt.retrieve("destinationorgid"),
        namespace: this.sessiSt.retrieve("namespace"),
        database: this.sessiSt.retrieve("selecteddb")
      };
      upsertcsvBatch.push(this.http.post('https://87qw2021l0.execute-api.us-east-1.amazonaws.com/prod/ds_upsertcsv_db_to_s3', JSON.stringify(data)));
    });

    console.log("upsertcsvBatch" + JSON.stringify(upsertcsvBatch));
    Observable.forkJoin(upsertcsvBatch).subscribe(
      result => {
        console.log("upsertcsvBatchresult" + result);
      },
      error => {
        this.showerror = true;
        this.spinnerService.hide();
        console.log(error.message);
      }
    )
  }
}
