import { Component ,OnInit} from '@angular/core';
import { Router } from "@angular/router";
import { SessionStorageService } from "ngx-webstorage";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
constructor(
  private router: Router,
  public sessiSt: SessionStorageService
){}

ngOnInit() {
      this.router.navigate(["/"]);
}
}
