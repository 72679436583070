import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, ViewEncapsulation } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import {
  NgForm,
  FormGroup,
  FormControl,
  FormGroupDirective,
  FormBuilder,
  Validators
} from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { ErrorStateMatcher } from "@angular/material/core";
import { LocalStorageService, SessionStorageService } from "ngx-webstorage";
import { Observable } from "rxjs/Observable";
import 'rxjs/add/observable/forkJoin';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Angular5Csv } from 'angular5-csv/Angular5-csv';
import { timestamp } from "rxjs/operators";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

declare interface DataTable {
  headerRow: string[];
  footerRow: string[];
  dataRows: { objectname: string , status: string, recordsprocessed: number,recordserror : number,error : string }[];
}

declare var $: any;

@Component({
  selector: "app-retryresult-cmp",
  templateUrl: "./retryresult.component.html",
  styleUrls: ["./retryresult.component.css"],
  encapsulation: ViewEncapsulation.None
})

export class retryresultComponent implements OnInit, AfterViewInit {
  @ViewChild("myTable") table: any;
  public dataTable: DataTable;

  show: boolean;

  importResultRows = [
    {
      objectname: "",
      status:"",
      recordsprocessed:"",
      recordserror:"",
      error:""
    }
  ];

  constructor(
    private element: ElementRef,
    private formBuilder: FormBuilder,
    private router: Router,
    private http: HttpClient,
    private spinnerService: Ng4LoadingSpinnerService,
    public sessiSt: SessionStorageService,
    private modalService: NgbModal
  ) 
  {
    this.importResultRows = [];
  }


  ngOnInit() {

    this.spinnerService.show();
    this.importResultRows = this.sessiSt.retrieve("retryresult");
    console.log("importResultRows"+JSON.stringify(this.importResultRows));


    this.importResultRows.forEach((element) => {
      console.log("element" + element);
      console.log("elementrecordserror" + element.recordserror);
      if (element.recordserror != '0'){
        this.show = true;
        console.log("showin"+this.show);
      }
    });

    console.log("show"+this.show);

    this.spinnerService.hide();
    // const data = {
    // accessToken: this.sessiSt.retrieve("accessToken"),
    // instanceUrl: this.sessiSt.retrieve("instanceUrl"),
    // object: this.sessiSt.retrieve("sfextidlist"),
    // namespace: this.sessiSt.retrieve("namespace")
    // };

    // console.log("extdataaaaa"+ JSON.stringify(data));
    // const that = this;
    // this.http
    //   .post(
    //     "https://jsbmrr9uh5.execute-api.us-east-1.amazonaws.com/prod/ds_remove_sf_extid",
    //     JSON.stringify(data)
    //   )
    //   .subscribe(
    //     result => {
    //       this.spinnerService.hide();
    //       console.log("Result"+JSON.stringify(result));

    //     },
    //     error => {
    //       console.log(error.message);
    //     }
    //   );

// Wizard Initialization starts here
$(".card-wizard").bootstrapWizard({
  tabClass: "nav nav-pills",
  nextSelector: ".btn-next",
  previousSelector: ".btn-previous",

  onInit: function(tab: any, navigation: any, index: any) {
    // check number of tabs and fill the entire row
    let $total = navigation.find("li").length;
    const $wizard = navigation.closest(".card-wizard");

    const $first_li = navigation.find("li:first-child a").html();

    $total = $wizard.find(".nav li").length;
    let $li_width = 100 / $total;

    const total_steps = $wizard.find(".nav li").length;
    let move_distance = $wizard.width() / total_steps;
    let index_temp = index;
    let vertical_level = 0;

    const mobile_device = $(document).width() < 600 && $total > 3;

    if (mobile_device) {
      move_distance = $wizard.width() / 2;
      index_temp = index % 2;
      $li_width = 50;
    }

    $wizard.find(".nav li").css("width", $li_width + "%");

    const step_width = move_distance;
    move_distance = move_distance * index_temp;

    const $current = index + 1;

    if ($current === 1 || (mobile_device === true && index % 2 === 0)) {
      move_distance -= 8;
    } else if (
      $current === total_steps ||
      (mobile_device === true && index % 2 === 1)
    ) {
      move_distance += 8;
    }

    if (mobile_device) {
      const x: any = index / 2;
      // tslint:disable-next-line:radix
      vertical_level = parseInt(x);
      vertical_level = vertical_level * 38;
    }
  }
});
$(".set-full-height").css("height", "auto");
// Wizard Initialization ends here

  }


  ngOnDestroy() {
  }

  ngAfterViewInit() {

  }

  exporttoCSV() {

    var options = { 
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      useBom: false,
      headers: ["Object Name", "Status", "Records Proccessed","Records Error","ErrorMessage"]
    };

    new Angular5Csv(this.importResultRows,'ImportResult',options);

   // this.router.navigate(["/datasetoption"]);

  //  this.spinnerService.show();
  //  const data = {
  //    database: this.sessiSt.retrieve("selecteddb")
  //    };
  //    const that = this;
  //    this.http
  //      .post(
  //        "https://23tvjan3f7.execute-api.us-east-1.amazonaws.com/prod/ds_remove_isinserted_flg",
  //        JSON.stringify(data)
  //      )
  //      .subscribe(
  //        result => {
  //          this.spinnerService.hide();
  //         // this.router.navigate(["/salesforce-data"]);
  //        },
  //        error => {
  //          console.log(error.message);
  //        }
  //      ); 

}
onContinue(){
  this.router.navigate(["/datasetoption"]);
}

onClose(){
  this.spinnerService.show();
  const data = {
    database: this.sessiSt.retrieve("selecteddb")
    };
    const that = this;
    this.http
      .post(
        "https://23tvjan3f7.execute-api.us-east-1.amazonaws.com/prod/ds_remove_isinserted_flg",
        JSON.stringify(data)
      )
      .subscribe(
        result => {
          this.spinnerService.hide();
          this.router.navigate(["/salesforce-data"]);
        },
        error => {
          console.log(error.message);
        }
      )


}

openLg(content) {
  this.modalService.open(content, { size: 'lg' });
}


errorlist: Array<any>;
onRetry(){

  this.spinnerService.show();
  const data = {
    database: this.sessiSt.retrieve("selecteddb")
    };
    const that = this;
    this.http
      .post(
        "https://23tvjan3f7.execute-api.us-east-1.amazonaws.com/prod/ds_remove_isinserted_flg",
        JSON.stringify(data)
      )
      .subscribe(
        result => {
          console.log("result......"+JSON.stringify(result));
          console.log("errorlist....."+this.sessiSt.retrieve("errorlist"))
          

          this.errorlist = this.sessiSt.retrieve("errorlist");

          var upsertionlist = [];
          let upsertcsvBatch = [];

          this.errorlist.forEach((element) => {
            console.log("element" + element);
            const data = {
              object: element,
              s3folder: this.sessiSt.retrieve("destinationorgid"),
              namespace: this.sessiSt.retrieve("namespace"),
              database: this.sessiSt.retrieve("selecteddb")
            };
            const upsertdata = {
              object: element,
              s3folder: this.sessiSt.retrieve("destinationorgid"),
              accessToken: accessToken,
              instanceUrl: instanceUrl,
              namespace: this.sessiSt.retrieve("namespace")
            };
            upsertcsvBatch.push(this.http.post('https://87qw2021l0.execute-api.us-east-1.amazonaws.com/prod/ds_upsertcsv_db_to_s3', JSON.stringify(data)));
            upsertionlist.push(this.http.post('https://jsbmrr9uh5.execute-api.us-east-1.amazonaws.com/prod/ds_sf_object_upsert', JSON.stringify(upsertdata)));
      
          });

          console.log("upsertcsvBatch" + JSON.stringify(upsertcsvBatch));
          Observable.forkJoin(upsertcsvBatch).subscribe(
            result => {
              console.log("result12343" + result);
            },
            error => {
              console.log(error.message);
            }
          )


          var accessToken = this.sessiSt.retrieve("accessToken");
          var instanceUrl = this.sessiSt.retrieve("instanceUrl");
          console.log("JSON.stringify" + JSON.stringify(this.errorlist).replace(/\[/g, "").replace(/\]/g, "").replace(/"/g, "'"));
          const rankdata = {
            accessToken: accessToken,
            instanceUrl: instanceUrl,
            object: JSON.stringify(this.errorlist).replace(/\[/g, "").replace(/\]/g, "").replace(/"/g, "'"),
            database: this.sessiSt.retrieve("selecteddb")
          };
      
          var retrylist = [];
          console.log("JSON.stringify(data)" + JSON.stringify(rankdata));
          this.http
            .post(
              "https://87qw2021l0.execute-api.us-east-1.amazonaws.com/prod/ds_get_insertion_order",
              JSON.stringify(rankdata)
            )
            .subscribe(
              result => {
                console.log("Result" + JSON.parse(JSON.stringify(result)));
                var sorted = JSON.parse(JSON.stringify(result)).sort(function (a, b) {
                  return a.rank - b.rank;
                });
      
                for (var i = 0; i < sorted.length; i++) {
                  retrylist.push(sorted[i].object)
                }
                console.log("retrylist" + retrylist);
                let insertresult = [];

                async function asyncForEach(array, callback) {
                  for (let index = 0; index < array.length; index++) {
                    await callback(array[index], index, array)
                  }
                }


                const start = async () => {
            
                  await asyncForEach(retrylist, async (element) => {
                  var insertcsvdata = {
                  object: element,
                  s3folder: this.sessiSt.retrieve("destinationorgid"),
                  namespace: this.sessiSt.retrieve("namespace"),
                  database: this.sessiSt.retrieve("selecteddb")
                };
                console.log("insertcsvdata"+JSON.stringify(insertcsvdata));
                var objinsert = {
                  object: element,
                  s3folder: this.sessiSt.retrieve("destinationorgid"),
                  accessToken: accessToken,
                  instanceUrl: instanceUrl,
                  namespace: this.sessiSt.retrieve("namespace")
                };
                console.log("objinsert......"+JSON.stringify(objinsert));
                var updatetable = {
                  object: element,
                  database: this.sessiSt.retrieve("selecteddb")
                };
                
                await this.http.post('https://87qw2021l0.execute-api.us-east-1.amazonaws.com/prod/ds_insertcsv_db_to_s3', 
                JSON.stringify(insertcsvdata)).toPromise().
                then(async response => 
                 {
                   console.log("response"+response);
                  if (response == 'uploaded'){
                
                     await this.http.post('https://jsbmrr9uh5.execute-api.us-east-1.amazonaws.com/prod/ds_sf_object_insert', 
                    JSON.stringify(objinsert)).toPromise().
                    then( async res =>{
                          
                      if ((<any>res).status == 'success'){
            
                        await this.http.post('https://87qw2021l0.execute-api.us-east-1.amazonaws.com/prod/ds_update_inserted_flag', 
                        JSON.stringify(updatetable)).toPromise().
                        then(result =>{
                          console.log("Completed"+JSON.stringify(result)+ element)
                          if ((<any>res).recordsfailed > 0){
                            insertresult.push({objectname: element,status:"error",recordsprocessed:(<any>res).recordsprocessed,recordserror:(<any>res).recordsfailed,error:(<any>res).error});
                          }else{
                            insertresult.push({objectname: element,status:"success",recordsprocessed:(<any>res).recordsprocessed,recordserror:(<any>res).recordsfailed,error:(<any>res).error});
                          }
                        })
                        .catch( error => { console.error("errorrupdateinsertedflg" + error.name)
                      }
                      )
                      }
                    })
                    .catch( error => { console.error("errorrsfobjectinsert" + error.name)
                  }
                      )
                    }
                    else if (response == 'uploadedwithoutrecords'){
                      insertresult.push({objectname: element,status:"success",recordsprocessed:0,recordserror:0,error:''});
                    }
                    else{
                      console.log("not uploaded");
                    }
                  })    
                  .catch( error => { console.error("errorr---insertcsvdbtos3" + error.name)
              }
                    )
                })
              }
              start().then ( x => {
                console.log("Finished:"+JSON.stringify(x));
                console.log("insertresult"+JSON.stringify(insertresult));
                
                Observable.forkJoin(upsertionlist).subscribe(
                  result => {
                    console.log("upsertion" + JSON.stringify(result));

                    this.spinnerService.hide();
                    this.router.navigate(["/retryresult"]);
                  },
                  error => {
                    console.log(error.message);
                    this.spinnerService.hide();
                    this.router.navigate(["/retryresult"]);

                  }
                )
            })
        },
        error => {
          console.log(error.message);
        }
      ); 

}, error => {
  console.log(error.message);
})
}

}
