import { Component, OnInit, ElementRef, ViewChild,OnDestroy } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import {
NgForm,
FormGroup,
FormControl,
FormGroupDirective,
FormBuilder,
Validators
} from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { ErrorStateMatcher } from "@angular/material/core";
import { LocalStorageService, SessionStorageService } from "ngx-webstorage";
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

declare var $: any;

export class MyErrorStateMatcher implements ErrorStateMatcher {
isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: "app-importfromdb-cmp",
  templateUrl: "./importfromdb.component.html",
  styleUrls: ["./importfromdb.component.css"]
})

export class importfromdbComponent implements OnInit, OnDestroy {

matcher = new MyErrorStateMatcher();
 @ViewChild("sfDataForm") form: NgForm;
 sfFormControl = new FormControl("", [
    Validators.required,
    Validators.email
  ]);
sforg: FormGroup;

ErrorMessage : string;
accessToken : string;
instanceUrl : string;
orgid : string;
email: string;
// type = [
//     { value: "https://login.salesforce.com", viewValue: "Production" },
//     { value: "https://test.salesforce.com", viewValue: "Sandbox" }
//   ];
  constructor(
  private element: ElementRef,
  private formBuilder: FormBuilder,
  private router: Router,
  private http: HttpClient,
  private spinnerService: Ng4LoadingSpinnerService,
  public sessiSt: SessionStorageService
   ) {
  }

  ngOnInit() {

     this.sforg = this.formBuilder.group({
      sfusername: ["", [Validators.required,
              Validators.pattern(
            "^([a-zA-Z0-9_\\-\\.]+)@((\\[a-z]{1,3}\\.[a-z]{1,3}\\.[a-z]{1,3}\\.)|(([a-zA-Z0-9\\-]+\\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})$")
            ]],
      sfpassword: ["", [Validators.required]],
      sftoken: ["", [Validators.required]],
      type: ["", [Validators.required]]
    });

     // Wizard Initialization starts here
     $(".card-wizard").bootstrapWizard({
      tabClass: "nav nav-pills",
      nextSelector: ".btn-next",
      previousSelector: ".btn-previous",

      onInit: function (tab: any, navigation: any, index: any) {
        // check number of tabs and fill the entire row
        let $total = navigation.find("li").length;
        const $wizard = navigation.closest(".card-wizard");

        const $first_li = navigation.find("li:first-child a").html();

        $total = $wizard.find(".nav li").length;
        let $li_width = 100 / $total;

        const total_steps = $wizard.find(".nav li").length;
        let move_distance = $wizard.width() / total_steps;
        let index_temp = index;
        let vertical_level = 0;

        const mobile_device = $(document).width() < 600 && $total > 3;

        if (mobile_device) {
          move_distance = $wizard.width() / 2;
          index_temp = index % 2;
          $li_width = 50;
        }

        $wizard.find(".nav li").css("width", $li_width + "%");

        const step_width = move_distance;
        move_distance = move_distance * index_temp;

        const $current = index + 1;

        if ($current === 1 || (mobile_device === true && index % 2 === 0)) {
          move_distance -= 8;
        } else if (
          $current === total_steps ||
          (mobile_device === true && index % 2 === 1)
        ) {
          move_distance += 8;
        }

        if (mobile_device) {
          const x: any = index / 2;
          // tslint:disable-next-line:radix
          vertical_level = parseInt(x);
          vertical_level = vertical_level * 38;
        }
      }
    });
    $(".set-full-height").css("height", "auto");
    // Wizard Initialization ends here

  }
  ngOnDestroy() {
  }

  onSubmit() {
    this.spinnerService.show();
   const data = {
       sfusername: this.form.value.sfusername,
       sfpassword: this.form.value.sfpassword,
       sftoken: this.form.value.sftoken,
       type: this.form.value.type
   };
   console.log("data"+JSON.stringify(data));
    const that = this;
    this.http
    .post(
      "https://23tvjan3f7.execute-api.us-east-1.amazonaws.com/prod/ds_sf_auth",
      JSON.stringify(data)
      )
    .subscribe(
    result => {
      this.spinnerService.hide();
    if ((<any>result).errorType == 'Error' || (<any>result).errorMessage != null){
    return this.ErrorMessage = (<any>result).errorMessage;
    }
    else{
        console.log("else"+JSON.stringify(result));
        
        this.accessToken = (<any>result).accessToken;
        this.instanceUrl = (<any>result).instanceUrl;
        this.orgid = (<any>result).orgid;

        this.sessiSt.store("accessToken", this.accessToken);
        this.sessiSt.store("instanceUrl", this.instanceUrl);
        this.sessiSt.store("destinationorgid", this.orgid);

      // this.router.navigate(["/database"]);
       this.router.navigate(["/importresult"]);
    }
    },
    error => {
        console.log(error.message);
    }
    )
  }

}
