import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from "@angular/router";
import { AuthService } from "./auth.service";
import swal from "sweetalert2";
declare var $: any;
// import decode from "jwt-decode";
@Injectable()
export class RoleGuard implements CanActivate {
  userRole = [];
  constructor(private authService: AuthService, private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const isAuthenticated = this.authService.isAuthenticated();
    isAuthenticated.subscribe(
      result => {
        // console.log("Login : " + result);
        const loggedInUser = this.authService.getAuthenticatedUser();
        const that = this;
        loggedInUser.getSession(function(err, session) {
          if (err) {
            console.error(err.message);
            return;
          }
          // console.log("Session validity: " + session.isValid());
          const sessionIdInfo = session.getIdToken().payload;
          // console.log("Group Info :" + sessionIdInfo["cognito:groups"]);
          that.userRole = sessionIdInfo["cognito:groups"];
          // console.log("User Group is: " + that.userRole);
        });
        const expectedRole = route.data.expectedRole;
        if (
          !this.authService.isAuthenticated() ||
          this.userRole[0] !== expectedRole
        ) {
          // this.showSwal("not-authorized");
          // console.log("You dont have access");
          return false;
        } else {
          // this.router.navigate(["/profile"]);
        }
      },
      error => {
        console.log(error.message);
      }
    );
    return true;
  }
  /** Alert Messages */
  showSwal(type: string, notification?: string) {
    const that = this;
    if (type === "user-already-exists-message") {
      swal({
        type: "warning",
        title: notification,
        // text: 'Please proceed to Login',
        buttonsStyling: false,
        confirmButtonClass: "btn btn-warning"
      });
    } else if (type === "not-authorized") {
      swal({
        title: notification,
        // text: "You will not be able to revert this!",
        type: "warning",
        showCancelButton: false,
        confirmButtonClass: "btn btn-success",
        cancelButtonClass: "btn btn-danger",
        confirmButtonText: "Go Back",
        buttonsStyling: false
      })
        .then(function() {
          that.router.navigate(["/dasboard"]);
        })
        .catch(swal.noop);
    } else if (type === "input-field") {
      swal({
        title: "Input something",
        html:
          '<div class="form-group">' +
          '<input id="input-field" type="text" class="form-control" />' +
          "</div>",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success",
        cancelButtonClass: "btn btn-danger",
        buttonsStyling: false
      })
        .then(function(result) {
          swal({
            type: "success",
            html:
              "You entered: <strong>" + $("#input-field").val() + "</strong>",
            confirmButtonClass: "btn btn-success",
            buttonsStyling: false
          });
        })
        .catch(swal.noop);
    }
  }
}
