import { Component, OnInit, ElementRef, ViewChild,OnDestroy } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import {
NgForm,
FormGroup,
FormControl,
FormGroupDirective,
FormBuilder,
Validators
} from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { ErrorStateMatcher } from "@angular/material/core";
import { LocalStorageService, SessionStorageService } from "ngx-webstorage";
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

declare var $: any;

export class MyErrorStateMatcher implements ErrorStateMatcher {
isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: "app-sfprofileimport-cmp",
  templateUrl: "./sfprofileimport.component.html",
  styleUrls: ["./sfprofileimport.component.css"]
})

export class sfprofileimportComponent implements OnInit, OnDestroy {

matcher = new MyErrorStateMatcher();
 @ViewChild("sfDataForm") form: NgForm;
 sfFormControl = new FormControl("", [
    Validators.required,
    Validators.email
  ]);
sforg: FormGroup;

ErrorMessage : string;
accessToken : string;
instanceUrl : string;
orgid : string;
email: string;
// type = [
//     { value: "https://login.salesforce.com", viewValue: "Production" },
//     { value: "https://test.salesforce.com", viewValue: "Sandbox" }
//   ];
  constructor(
  private element: ElementRef,
  private formBuilder: FormBuilder,
  private router: Router,
  private http: HttpClient,
  private spinnerService: Ng4LoadingSpinnerService,
  public sessiSt: SessionStorageService
   ) {
  }

  ngOnInit() {

     this.sforg = this.formBuilder.group({
      sfusername: ["", [Validators.required,
              Validators.pattern(
            "^([a-zA-Z0-9_\\-\\.]+)@((\\[a-z]{1,3}\\.[a-z]{1,3}\\.[a-z]{1,3}\\.)|(([a-zA-Z0-9\\-]+\\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})$")
            ]],
      sfpassword: ["", [Validators.required]],
      sftoken: [""],
    //  sftoken: ["", [Validators.required]],
      type: ["", [Validators.required]]
    });

  }
  ngOnDestroy() {
  }

  onSubmit() {
    this.spinnerService.show();
   const data = {
       sfusername: this.form.value.sfusername,
       sfpassword: this.form.value.sfpassword,
       sftoken: this.form.value.sftoken,
       type: this.form.value.type
   };
   console.log("data"+JSON.stringify(data));
    const that = this;
    this.http
    .post(
      "https://23tvjan3f7.execute-api.us-east-1.amazonaws.com/prod/ds_sf_auth",
      JSON.stringify(data)
      )
    .subscribe(
    result => {
      
    if ((<any>result).errorType == 'Error' || (<any>result).errorMessage != null){
      this.spinnerService.hide();
    return this.ErrorMessage = (<any>result).errorMessage;
    }
    else{
        console.log("else"+JSON.stringify(result));
        
        this.accessToken = (<any>result).accessToken;
        this.instanceUrl = (<any>result).instanceUrl;
        this.orgid = (<any>result).orgid;

        this.sessiSt.store("accessToken", this.accessToken);
        this.sessiSt.store("instanceUrl", this.instanceUrl);
        this.sessiSt.store("destinationorgid", this.orgid);
        this.spinnerService.hide();
       this.router.navigate(["/database"]);
      
    }
    },
    error => {
        console.log(error.message);
    }
    )
  }

}
