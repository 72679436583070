import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs/Subject";

import { Observable } from "rxjs/Observable";
import { BehaviorSubject } from "rxjs/BehaviorSubject";
import {
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  AuthenticationDetails,
  CognitoUserSession
} from "amazon-cognito-identity-js";
import { User } from "./user.model";
import { environment } from "../../environments/environment";
import swal from "sweetalert2";
import { CognitoIdentityServiceProvider } from "aws-sdk";
import { stringList } from "aws-sdk/clients/datapipeline";
import { HttpClient } from "@angular/common/http";
import { SessionStorageService } from "ngx-webstorage";
declare var $: any;
const POOL_DATA = {
  UserPoolId: environment.userPoolId,
  ClientId: environment.clientId
};
const userPool = new CognitoUserPool(POOL_DATA);

@Injectable()
export class AuthService {
  authIsLoading = new BehaviorSubject<boolean>(false);
  authDidFail = new BehaviorSubject<boolean>(false);
  authStatusChanged = new Subject<boolean>();
  registerdUser: CognitoUser;
  cognitoIdentityService = new CognitoIdentityServiceProvider();
  constructor(
    private http: HttpClient,
    private router: Router,
    public sessiSt: SessionStorageService
  ) {}
  signUp(username: string, email: string, password: string): void {
    this.authIsLoading.next(true);
    const user: User = {
      username: username,
      email: email,
      password: password
    };
    const attrList: CognitoUserAttribute[] = [];
    const emailAttribute = {
      Name: "email",
      Value: user.email
    };
    /*const dataPhoneNumber = {
      Name: "phone_number",
      Value: "+12028765834"
    };*/
    attrList.push(new CognitoUserAttribute(emailAttribute));
    // attrList.push(new CognitoUserAttribute(dataPhoneNumber));
    userPool.signUp(
      user.username,
      user.password,
      attrList,
      null,
      (err, result) => {
        if (err) {
          this.showSwal("user-already-exists-message", err.message);
          this.authDidFail.next(true);
          this.authIsLoading.next(false);
          return;
        } else {
          this.authDidFail.next(false);
          this.authIsLoading.next(false);
          this.registerdUser = result.user;
          this.router.navigate(["/pages/confirmRegistration", user.username]);
          /** Adding user to group */
          /** const userData = {
            "username": user.username,
            "groupName": "Product_Development",
            "userPoolID": environment.userPoolId
          };
          console.log(userData);
          this.http
            .post(
              "https://i8335s40xi.execute-api.us-east-1.amazonaws.com/prod/fondash_user_to_group",
              JSON.stringify(userData)
            )
            .subscribe(
              // tslint:disable-next-line:no-shadowed-variable
              result => {
                console.log(result);
              },
              error => {
                console.log(error);
              }
            );*/
        }
      }
    );
    return;
  }
  confirmUser(username: string, code: string) {
    this.authIsLoading.next(true);
    const userData = {
      Username: username,
      Pool: userPool
    };
    const cognitUser = new CognitoUser(userData);
    cognitUser.confirmRegistration(code, true, (err, result) => {
      if (err) {
        console.log(err);
        this.showSwal("user-already-exists-message", err.message);
        this.authDidFail.next(true);
        this.authIsLoading.next(false);
        return;
      } else {
        this.authDidFail.next(false);
        this.authIsLoading.next(false);
        // add the user to the group here
        this.router.navigate(["/pages/login"]);
      }
    });
  }
  signIn(username: string, password: string): void {
    this.authIsLoading.next(true);
    const authData = {
      Username: username,
      Password: password
    };
    const authDetails = new AuthenticationDetails(authData);
    const userData = {
      Username: username,
      Pool: userPool
    };
    const cognitoUser = new CognitoUser(userData);
    const that = this;
    cognitoUser.authenticateUser(authDetails, {
      onSuccess(result: CognitoUserSession) {
        that.authStatusChanged.next(true);
        that.authDidFail.next(false);
        that.authIsLoading.next(false);
        // that.showSwal("input-field");
        that.getAuthenticatedUser().getSession((err, session) => {
          /*console.log(
            "Group : " + session.getIdToken().payload["cognito:groups"]
          );*/
        //  if (session.getIdToken().payload["cognito:groups"] !== undefined) {
            if (session.getIdToken().payload["cognito:groups"] == 'DSUser') {
              console.log("user")
           // that.router.navigate(["/dashboard"], { relativeTo: this.route });
           //  that.router.navigate(["/salesforce-data"], { relativeTo: this.route });
            that.router.navigate(["/importfromdb"], { relativeTo: this.route });

          } 
          else if (session.getIdToken().payload["cognito:groups"] == 'DSAdmin') {
            console.log("admin")
            // that.router.navigate(["/dashboard"], { relativeTo: this.route });
              that.router.navigate(["/salesforce-data"], { relativeTo: this.route });
           //  that.router.navigate(["/importfromdb"], { relativeTo: this.route });
 
           }
          
          else {
             console.log("no group");
            // that.router.navigate(["/pages/profile"]);
            //that.router.navigate(["/dashboard"]);
           //  that.router.navigate(["/salesforce-data"]);
            that.router.navigate(["/importfromdb"]);
          }
        });
      },
      onFailure(err) {
        err.message.includes("not confirmed")
          ? that.showSwal("user-not-confirmed-message", err.message)
          : that.showSwal("user-already-exists-message", err.message);
        that.authDidFail.next(true);
        that.authIsLoading.next(false);
        // console.log(err);
      }
    });
    this.authStatusChanged.next(true);
    return;
  }
  getAuthenticatedUser() {
    return userPool.getCurrentUser();
  }
  logout() {
    this.getAuthenticatedUser().signOut();
    this.authStatusChanged.next(false);
    this.router.navigate(["/pages/login"]);
    this.sessiSt.clear();
  }
  isAuthenticated(): Observable<boolean> {
    const user = this.getAuthenticatedUser();
    const obs = Observable.create(observer => {
      if (!user) {
        observer.next(false);
      } else {
        user.getSession((err, session) => {
          if (err) {
            observer.next(false);
          } else {
            if (session.isValid()) {
              observer.next(true);
            } else {
              observer.next(false);
            }
          }
        });
      }
      observer.complete();
    });
    return obs;
  }
  initAuth() {
    this.isAuthenticated().subscribe(auth => this.authStatusChanged.next(auth));
  }
  getCode(username: string) {
    this.authIsLoading.next(true);
    const userData = {
      Username: username,
      Pool: userPool
    };
    const cognitoUser = new CognitoUser(userData);
    const that = this;
    cognitoUser.resendConfirmationCode((err, result) => {
      if (err) {
        console.log(err);
        this.showSwal("user-already-exists-message", err.message);
        this.authDidFail.next(true);
        this.authIsLoading.next(false);
        return;
      } else {
        this.authDidFail.next(false);
        this.authIsLoading.next(false);
        this.router.navigate(["/pages/confirmRegistration", username]);
      }
    });
  }
  forgotPassword(username: string) {
    this.authIsLoading.next(true);
    const userData = {
      Username: username,
      Pool: userPool
    };
    const cognitoUser = new CognitoUser(userData);
    const that = this;
    cognitoUser.forgotPassword({
      onSuccess(result) {
        /*console.log("success");
        that.authStatusChanged.next(true);
        that.authDidFail.next(false);
        that.authIsLoading.next(false);
        that.router.navigate(["/instances"], { relativeTo: this.route });*/
      },
      onFailure(err) {
        err.message.includes("not confirmed")
          ? that.showSwal("user-not-confirmed-message", err.message)
          : that.showSwal("user-already-exists-message", err.message);
        that.authDidFail.next(true);
        that.authIsLoading.next(false);
        console.log(err);
      },
      inputVerificationCode() {
        // console.log("code here");
        // this.authDidFail.next(false);
        // this.authIsLoading.next(false);
        that.router.navigate(["/pages/forgotPassword", username]);
      }
    });
  }
  newPassword(username: string, verificationCode: string, password: string) {
    this.authIsLoading.next(true);
    const userData = {
      Username: username,
      Pool: userPool
    };
    const cognitoUser = new CognitoUser(userData);
    const that = this;
    cognitoUser.confirmPassword(verificationCode, password, {
      onSuccess() {
        that.router.navigate(["/pages/login", username]);
        return;
      },
      onFailure(err) {
        err.message.includes("not confirmed")
          ? that.showSwal("user-not-confirmed-message", err.message)
          : that.showSwal("user-already-exists-message", err.message);
        that.authDidFail.next(true);
        that.authIsLoading.next(false);
        console.log(err);
      }
    });
  }

  /** Alert Messages */
  showSwal(type: string, notification?: string) {
    const that = this;
    if (type === "user-already-exists-message") {
      swal({
        type: "warning",
        title: notification,
        // text: 'Please proceed to Login',
        buttonsStyling: false,
        confirmButtonClass: "btn btn-warning"
      });
    } else if (type === "user-not-confirmed-message") {
      swal({
        title: notification,
        // text: "You will not be able to revert this!",
        type: "warning",
        showCancelButton: false,
        confirmButtonClass: "btn btn-success",
        cancelButtonClass: "btn btn-danger",
        confirmButtonText: "Lets Activate",
        buttonsStyling: false
      })
        .then(function() {
          that.router.navigate(["/pages/resend"]);
        })
        .catch(swal.noop);
    } else if (type === "input-field") {
      swal({
        title: "Input something",
        html:
          '<div class="form-group">' +
          '<input id="input-field" type="text" class="form-control" />' +
          "</div>",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success",
        cancelButtonClass: "btn btn-danger",
        buttonsStyling: false
      })
        .then(function(result) {
          swal({
            type: "success",
            html:
              "You entered: <strong>" + $("#input-field").val() + "</strong>",
            confirmButtonClass: "btn btn-success",
            buttonsStyling: false
          });
        })
        .catch(swal.noop);
    }
  }
}
