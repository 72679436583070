import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, ViewEncapsulation } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import {
  NgForm,
  FormGroup,
  FormControl,
  FormGroupDirective,
  FormBuilder,
  Validators
} from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { ErrorStateMatcher } from "@angular/material/core";
import { LocalStorageService, SessionStorageService } from "ngx-webstorage";
import { Observable } from "rxjs/Observable";
import 'rxjs/add/observable/forkJoin';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

declare interface DataTable {
  headerRow: string[];
  footerRow: string[];
  dataRows: { objectName: string }[];
}

declare var $: any;

@Component({
  selector: "app-objList-cmp",
  templateUrl: "./objList.component.html",
  styleUrls: ["./objList.component.css"],
  encapsulation: ViewEncapsulation.None
})

export class objListComponent implements OnInit, AfterViewInit {
  selected = [];
  @ViewChild("myTable") table: any;
  public dataTable: DataTable;
  temp: Array<any>;
  selectedObj = [];

  objListRows = [
    {
      name: ""
    }
  ];
  totalRows = [
    {
      name: ""
    }
  ];
  constructor(
    private element: ElementRef,
    private formBuilder: FormBuilder,
    private router: Router,
    private http: HttpClient,
    private spinnerService: Ng4LoadingSpinnerService,
    public sessiSt: SessionStorageService
  ) {
    this.objListRows = [];
  }


  ngOnInit() {
   this.spinnerService.show();
    const data = {
    accessToken: this.sessiSt.retrieve("accessToken"),
    instanceUrl: this.sessiSt.retrieve("instanceUrl")
    };
    const that = this;
    const ObjData: Array<{
      name: any;
    }> = [];
    this.http
      .post(
        "https://3nybj80hha.execute-api.us-east-1.amazonaws.com/prod/ds_get_objlist",
        JSON.stringify(data)
      )
      .subscribe(
        result => {
          this.spinnerService.hide();
          Object.keys(result).forEach(key => {
            console.log("retrieve"+this.sessiSt.retrieve("dbobjList"));
            if(this.sessiSt.retrieve("dbobjList").indexOf(result[key].toLowerCase())> -1){
              console.log("result[key]inside"+result[key]);
            ObjData.push({
              name: result[key]
            });
          }
          });
          this.totalRows = ObjData;
          this.temp = ObjData;
          this.objListRows = ObjData;
          console.log("this.temp"+this.temp);
          console.log("this.objListRows"+this.objListRows);

        },
        error => {
          console.log(error.message);
        }
      );

// Wizard Initialization starts here
$(".card-wizard").bootstrapWizard({
  tabClass: "nav nav-pills",
  nextSelector: ".btn-next",
  previousSelector: ".btn-previous",

  onInit: function(tab: any, navigation: any, index: any) {
    // check number of tabs and fill the entire row
    let $total = navigation.find("li").length;
    const $wizard = navigation.closest(".card-wizard");

    const $first_li = navigation.find("li:first-child a").html();

    $total = $wizard.find(".nav li").length;
    let $li_width = 100 / $total;

    const total_steps = $wizard.find(".nav li").length;
    let move_distance = $wizard.width() / total_steps;
    let index_temp = index;
    let vertical_level = 0;

    const mobile_device = $(document).width() < 600 && $total > 3;

    if (mobile_device) {
      move_distance = $wizard.width() / 2;
      index_temp = index % 2;
      $li_width = 50;
    }

    $wizard.find(".nav li").css("width", $li_width + "%");

    const step_width = move_distance;
    move_distance = move_distance * index_temp;

    const $current = index + 1;

    if ($current === 1 || (mobile_device === true && index % 2 === 0)) {
      move_distance -= 8;
    } else if (
      $current === total_steps ||
      (mobile_device === true && index % 2 === 1)
    ) {
      move_distance += 8;
    }

    if (mobile_device) {
      const x: any = index / 2;
      // tslint:disable-next-line:radix
      vertical_level = parseInt(x);
      vertical_level = vertical_level * 38;
    }
  }
});
$(".set-full-height").css("height", "auto");
// Wizard Initialization ends here


  }


  ngOnDestroy() {
  }

  ngAfterViewInit() {

  }

  updateFilter(event) {
    const vala = event.target.value;
    const val = vala.toLowerCase();
    // filter our data
    const temp = this.temp.filter((d: any) => {
      // console.log(d);
      return d.name.toLowerCase().indexOf(val) !== -1 || !val;
    });
    // update the rows
    this.objListRows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }


  onSelect({ selected }) {
    console.log('Select Event', this.selected);
    console.log('Select Eventname', selected.name);

    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  onActivate(event) {
  }
  onSort(event) {
  }

  getMatch(a, b) {
    var matches = [];

    for (var i = 0; i < a.length; i++) {
      for (var e = 0; e < b.length; e++) {
        if (a[i] === b[e]) matches.push(a[i]);
      }
    }
    console.log("matches" + matches);
    return matches;
  }

  add() {
    console.log("this.selected.length" + this.selected.length);
    for (var i = 0; i < this.selected.length; i++) {
      var obj = this.selected[i];
      //console.log("obj"+obj);
      this.selectedObj.push(obj['name']);
    }

    console.log("this.selected" + this.selectedObj);

    console.log("allRowsSelected" + this.table.allRowsSelected);
    console.log("this.ObjData" + this.totalRows.length);
    console.log("this.selected.length" + this.selectedObj.length);

    if (this.table.allRowsSelected == true && this.totalRows.length == this.selectedObj.length) {
      this.sessiSt.store("getmatch", this.selectedObj);
      this.spinnerService.hide();
      this.router.navigate(["/importList"]);
    }
    else {
      let selectedobjBatch = [];
      this.selectedObj.forEach((element) => {
        const data = {
          accessToken: this.sessiSt.retrieve("accessToken"),
          instanceUrl: this.sessiSt.retrieve("instanceUrl"),
          object: element
        };
        selectedobjBatch.push(this.http.post('https://3nybj80hha.execute-api.us-east-1.amazonaws.com/prod/ds_get_ref_objs', JSON.stringify(data)));
      });
      let refData = [];
      Observable.forkJoin(selectedobjBatch).subscribe(
        result => {
          refData = this.selectedObj.concat(result);
          console.log("refData1"+JSON.stringify(refData).replace(/,\[/g,',').replace(/\],/g, ',').replace(/\]\]/g, ']').replace(/\[\[/g, "[").replace(/,\[\]/g,"").replace(/\[\],/g,"").replace(/\\s/g,"").replace(/,,/g,",").replace(/,\]/g,"]").replace(/,null,/g,",").replace(/,\\s,/g,","));
          refData = JSON.parse(JSON.stringify(refData).replace(/,\[/g,',').replace(/\],/g, ',').replace(/\]\]/g, ']').replace(/\[\[/g, "[").replace(/,\[\]/g,"").replace(/\[\],/g,"").replace(/\\s/g,"").replace(/,,/g,",").replace(/,\]/g,"]").replace(/,null,/g,",").replace(/,\\s,/g,","));
          console.log("refData2"+refData);
          this.sessiSt.store("getmatch", Array.from(new Set(refData)));
          this.spinnerService.hide();
          this.router.navigate(["/importList"]);
        },
        error => {
          console.log(error.message);
        }
      )
    }
  }

}
